import React, { useState, useEffect } from "react";
import algoliasearch from 'algoliasearch/lite';
import SearchResultsTemplate from "../../templates/property-results";
import { SEOSEARCHRESULTS } from "../../components/seo-search-results"
import createResultsUrl from "../../utils/property-results/createResultsUrl";
import parseResultsUrl from "../../utils/property-results/parseResultsUrl";
import generateFiltersfromPageData from "../../utils/property-results/generateFiltersfromPageData";
import useSearchResultsSEO from "../../hooks/useSearchResultsSEO"

export default function SearchResults(props) {

    const [algoliadata, getAlgoliadata] = useState([]);
    // const [mysortbyoption, setMysortbyoption] = useState('');
    // const [mypageoption, setMypageoption] = useState('');
    // console.log("vvv1112", props.location)
    //the current page data are lives here
    const page_url_data = parseResultsUrl(props.location)

    // you can make default search page results filters here
    const myalgoliafilters = generateFiltersfromPageData(
        page_url_data['departmentVal'],
        page_url_data['searchtypeVal'],
        page_url_data['areaVal'],
        page_url_data['bedVal'],
        page_url_data['bathVal'],
        page_url_data['minpriceVal'],
        page_url_data['maxpriceVal'],
        page_url_data['statusVal'],
        page_url_data['buildingval']
    )

    // navigate to pages based on filters

    const { pageh1, introcopy } = useSearchResultsSEO(
        page_url_data['searchtypeVal'],
        page_url_data['areaVal'],
        page_url_data['buildingval'],
        page_url_data['bedVal'],
        page_url_data['bathVal'],
        page_url_data['minpriceVal'],
        page_url_data['maxpriceVal'],
        page_url_data['statusVal'],
        page_url_data['propertyTypeVal']
    )

    useEffect(() => {
        getAlgoliaResutls(myalgoliafilters);
    }, [props.location]);

    const getAlgoliaResutls = (myalgoliafilters) => {
        // lets run algolia search query to fetch hits, stats and number of pages
        const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY);
        const index = client.initIndex(page_url_data['sortVal'] ? page_url_data['sortVal'] : page_url_data['indexVal']);
        //const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME);
        // let query_filt = page_url_data['areaVal'].split("-and-")
        // let myareaquery = query_filt.map(function(area) {
        //     return area;
        // });
        index.search((page_url_data['areaVal'] == process.env.GATSBY_DEFAULT_AREA ? '' : page_url_data['areaVal']), {
            // similarQuery: myareaquery != process.env.GATSBY_DEFAULT_AREA ? myareaquery.join(' ') : '',
            filters: myalgoliafilters,
            page: (page_url_data['pageVal'] - 1),
            hitsPerPage: (page_url_data['layoutVal'] ? 1000 : 18)
        }).then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
            var myArray = { "total": nbHits, "current_page_number": page, "hitsPerPage": hitsPerPage, "number_of_pages": nbPages, "hits": hits };
            getAlgoliadata(myArray)
        });
    }
    let newdevelopmentspage = typeof window !== 'undefined' ? window.location.pathname.indexOf("/new-homes/for-sale") > -1 : "";
    return (
        <React.Fragment>
            <SearchResultsTemplate
                {...algoliadata}
                pageh1={pageh1}
                introcopy={introcopy}
                page_url_data={page_url_data}
                createResultsUrl={createResultsUrl}
                location_path={props.location.pathname}
                indexname={page_url_data['indexVal']}
                newdevelopmentspage={newdevelopmentspage}
            />
        </React.Fragment>
    )
}

export const Head = (props) => {
    //the current page data are lives here
    const page_url_data = parseResultsUrl(props.location)

    // you can make default search page results filters here
    const myalgoliafilters = generateFiltersfromPageData(
        page_url_data['departmentVal'],
        page_url_data['searchtypeVal'],
        page_url_data['areaVal'],
        page_url_data['bedVal'],
        page_url_data['bathVal'],
        page_url_data['minpriceVal'],
        page_url_data['maxpriceVal'],
        page_url_data['buildingval']
    )

    const { pagetitle, pagemetadesc } = useSearchResultsSEO(
        page_url_data['searchtypeVal'],
        page_url_data['areaVal'],
        page_url_data['buildingval'],
        page_url_data['bedVal'],
        page_url_data['bathVal'],
        page_url_data['minpriceVal'],
        page_url_data['maxpriceVal'],
        page_url_data['statusVal'],
        page_url_data['propertyTypeVal']
    )

    return (
        <SEOSEARCHRESULTS title={pagetitle} description={pagemetadesc} url={props.location.pathname} />
    )
}
